/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import "functions";
@import "variables";

$grid-breakpoints: (
  "xxs": 0,
  "xs": 480px,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1440px,
  "hdp": 1600px,
  "fhd": 1920px,
  "rt": 2560px,
  "rt15": 2880px,
  "uhd": 3840px,
  "4k": 4096px,
  "8k": 8192px,
  "16k": 15360px
);

$container-max-widths: (
  "xs": 480px,
  "sm": 540px,
  "md": 720px,
  "lg": 960px,
  "xl": 1140px,
  "xxl": 1320px,
  "hdp": 1410px,
  "fhd": 1800px,
  "rt": 2310px,
  "rt15": 2610px,
  "uhd": 3600px,
  "4k": 3810px,
  "8k": 7500px,
  "16k": 14010px
);

@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "utilities";
@import "print";
